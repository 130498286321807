import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { FeathersService } from '../services/feathers.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private feathersService: FeathersService, private router: Router) { }

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const user = await this.feathersService.getUser(); // Fetch the current user

    console.log('[AuthGuard] checking user permissions');

    // Get the allowed roles from the route data
    const allowedRoles = route.data['ruolo'];

    // Check if the user has one of the allowed roles
    if (user && allowedRoles.includes(user.ruolo)) {
      return true;

    } else {
      // Redirect to a different page if the user doesn't have the required role
      this.router.navigate(['/home']);
      return false;
    }
  }
}