import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { routes } from '../app.routes';
import { FeathersService } from '../services/feathers.service';

@Injectable({
  providedIn: 'root'
})
export class AppPagesService {
  private appPagesSubject = new BehaviorSubject<any[]>([]);
  public appPages$ = this.appPagesSubject.asObservable();

  constructor(
    private feathersService: FeathersService,
    private router: Router
  ) {
    this.generateAppPages();
  }

  private async generateAppPages() {
    const user = await this.feathersService.getUser();

    const appPages = routes
      .filter((route: any) => {
        // Filter routes based on role
        const allowedRoles = route.data?.ruolo;
        return route.data?.showInMenu && (!allowedRoles || (user && allowedRoles.includes(user.ruolo)));
      })
      .map((route: any) => ({
        title: route.data.title,
        url: `/${route.path}`,
        icon: route.data.icon
      }));

    // console.log('appPages', appPages);
    this.appPagesSubject.next(appPages);
  }
}
